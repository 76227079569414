
































import { SfButton, SfSearchBar } from '@storefront-ui/vue';
import { defineComponent, ref, computed, useContext, useRoute, useRouter, watch } from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import SvgImage from '~/components/General/SvgImage.vue';
import { useSearch } from '~/composables';

export default defineComponent({
  fetchOnServer: false,
  name: 'SearchBar',
  components: {
    SfSearchBar,
    SfButton,
    SvgImage,
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
  },
  emits: ['set-is-open', 'set-search-term', 'set-search-results-data'],
  setup(props, { emit }) {
    const term = ref('');
    const route = useRoute();
    const router = useRouter();
    const { app } = useContext();

    const showSearch = () => {
      term.value = '';
      emit('set-is-open', true);
      if (document) {
        document.body.classList.add('no-scroll');
      }
    };

    const hideSearch = () => {
      if (props.isSearchOpen) {
        emit('set-is-open', false);
        emit('set-search-term', '');
        emit('set-search-results-data', []);
        if (document) {
          document.body.classList.remove('no-scroll');
        }
      }
    };

    const closeSearch = (event: MouseEvent) => {
      if (document) {
        const searchResultsEl = document.querySelector('.search');
        const closeTriggerElement = event.target as HTMLElement;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          hideSearch();
        }
      } else {
        hideSearch();
      }
    };

    const { search } = useSearch();
    const productResult = ref(null);

    const rawHandleSearch = async (searchTerm: string) => {
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) return;

      let resData = await search(searchTerm);
      emit('set-search-results-data', resData);
      emit('set-search-term', searchTerm);

      productResult.value = resData.filter((el) => el.type === 'product');
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 200);

    const searchResultUrl = computed(() => {
      const resultCount = productResult.value?.length ?? 0
      if (resultCount === 0) {
        return app.localePath({ name: 'no-result-found', query: { term: term.value }})
      }
      if (resultCount === 1) {
        return app.localePath({ name: 'product', params: { slug: productResult.value[0].url }})
      }
      return app.localePath({ name: 'search', query: { term: term.value }})
    })

    const handleKeydownEnter = async (searchTerm: string) => {
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      debouncedHandleSearch.cancel();
      await rawHandleSearch(searchTerm);
      await router.push(searchResultUrl.value)
    };

    watch(route, () => {
      hideSearch();
      term.value = '';
    });

    return {
      showSearch,
      hideSearch,
      closeSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      term
    };
  },
});

